import React from "react";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./index.css";

import TableContratos from "../Tables/TableContratos.jsx";

const Index = () => {
  return (
    <>
      <Header icon="home" title="Contratos" />
      <div className="containerTelaConf"> 
        <div className="ContConfirmar">
          <TableContratos />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
