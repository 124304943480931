import {
  MDBCol,
  MDBRow
} from "mdb-react-ui-kit";
import { useContext, useEffect, useState } from "react";
import Card from "../CardHome/CardHome";

import ContratosContext from "../../context/ContratosContext";
import "./GridCardsHome.css";

const GridCards = (props) => {
  const [Dados, setDados] = useState([]);

  const { Totalizador } = useContext(ContratosContext);

  useEffect(() => {
    if(props.dTotalizador.length){
      setDados(JSON.parse(props.dTotalizador))
    }
  }, [props.dTotalizador]); 

  return (
    <MDBRow className='mt-3'>
      <MDBCol lg="6" md="12" sm="12" className='mb-2'>
        <Card
          title_4="VALOR TOTAL"
          value_4={ Dados.vTotal ? "R$ " + Dados.vTotal : 0 }

          title="TOTAL CRÉDITO"
          value={ Dados.vCredito ? "R$ " + Dados.vCredito : 0 }

          title_2="TOTAL DÉBITO"
          value_2={ Dados.vDebito ? "R$ " + Dados.vDebito : 0 }

          title_3="SALDO"
          value_3={ Dados.vSaldoF ? "R$ " + Dados.vSaldoF : 0 }
        ></Card>
      </MDBCol>
      <MDBCol lg="6" md="12" sm="12" className='mb-2'>
        <Card
          title="QUANTIDADE"
          value={ Dados.vQuantidade ? Dados.vQuantidade : 0 }

          title_2="QUANTIDADE CARREGADA"
          value_2={ Dados.vCarregado ? Dados.vCarregado  : 0  }

          title_3="SALDO À CARREGAR"
          value_3={ Dados.vSaldo ? Dados.vSaldo : 0 }
        ></Card>
      </MDBCol>
    </MDBRow>
  );
};

export default GridCards;
