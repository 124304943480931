import SyncIcon from '@mui/icons-material/Sync';
import { Button, LinearProgress, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  MDBCol
} from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import TableFilter from 'react-table-filter';
import 'react-table-filter/lib/styles.css';
import './Table.css';

import ContratosContext from "../../context/ContratosContext";

const TableFinan = (props) => {
  const [Finan, setFinan] = useState([]);
  const [Finan_o, setFinan_o] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [Load, setLoad] = useState(false);
  
  const { Close } = useContext(ContratosContext);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Finan.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function _filterUpdated(newData, filterConfiguration){
    setFinan(newData);
  }

  useEffect(() => {
    if(props.Finan){
      Format(props.Finan)
    }
  }, [props.Finan]);

  function Format(financeiros){
    setLoad(true);
    for (let i = 0; i < financeiros.length; i++) {
      financeiros[i].data_f = new Date(new Date(financeiros[i].data).setHours(24)).toLocaleDateString('pt-BR');
      financeiros[i].debitos_f = props.moeda + "" + fDecimal(financeiros[i].debitos);
      financeiros[i].pago_f = props.moeda + "" + fDecimal(financeiros[i].pago);
      financeiros[i].saldo_p_f = props.moeda + "" + fDecimal(financeiros[i].saldo_p);
      financeiros[i].tipo_f = financeiros[i].tipo === 0 ? "D" : "C" ;
    }

    setFinan(financeiros)
    setFinan_o(financeiros)
    setLoad(false);
  }

  function sinc(){
    setFinan(Finan_o)
  }

  return (
    <>
      { Load ? (
        <MDBCol md="12" className='mb-2'><LinearProgress /></MDBCol>
      ) : null }

      <MDBCol md="12" className='mb-1' style={{ display: "flex", justifyContent: "flex-end"}}>
        <Button variant="contained" size="small" color="success" onClick={() => sinc()}>
          <SyncIcon /> Atualizar
        </Button>
      </MDBCol>

      { Finan.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table" style={{ fontSize: 10 }}>
              <TableFilter
                rows={Finan}
                onFilterUpdate={_filterUpdated}
              >
                <TableCell size={"small"} component={"th"} key={"tipo_f"} filterkey="tipo_f" showsearch={"true"}> # </TableCell>
                <TableCell size={"small"} component={"th"} key={"data_f"} filterkey="data_f" showsearch={"true"}> Data </TableCell>
                <TableCell size={"small"} component={"th"} key={"nomerazao"} filterkey="nomerazao" showsearch={"true"}> Nome </TableCell>
                <TableCell size={"small"} component={"th"} key={"debitos_f"} filterkey="debitos_f" showsearch={"true"}> Débitos </TableCell>
                <TableCell size={"small"} component={"th"} key={"pago_f"} filterkey="pago_f" showsearch={"true"}> Pago </TableCell>
                <TableCell size={"small"} component={"th"} key={"saldo_p_f"} filterkey="saldo_p_f" showsearch={"true"}> Saldo </TableCell>
                <TableCell size={"small"} component={"th"} key={"documento"} filterkey="documento" showsearch={"true"} alignleft={"true"}> Doc. </TableCell>
              </TableFilter>
              
            
              <TableBody>
                {(rowsPerPage > 0 ? Finan.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : Finan ).map((row) => (
                  <>
                    <TableRow key={row.id}>
                      <TableCell size={"small"}>{ row.tipo_f }</TableCell>
                      <TableCell size={"small"}>{ row.data_f }</TableCell>
                      <TableCell size={"small"}>{ row.nomerazao}</TableCell>
                      <TableCell size={"small"}>{ row.debitos_f }</TableCell>
                      <TableCell size={"small"}>{ row.pago_f }</TableCell>
                      <TableCell size={"small"}>{ row.saldo_p_f }</TableCell>
                      <TableCell size={"small"}>{ row.documento}</TableCell>
                    </TableRow>
                  </>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="TbContratos">
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  
                  count={Finan.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={
                    ({ from, to, count }) => {
                      return 'Mostrando ' + from + '-' + to + ' de ' + count
                    }
                  }
                  labelRowsPerPage={ "Registros por página" }
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        </>
      ) : (
        <>
          <TableContainer component={Paper} className="TbContratos">
            <Table aria-label="custom pagination table" style={{ fontSize: 21 }}>
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell align="center" colSpan={12}>{"Sem registros."}</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
  
  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }
};

export default TableFinan;
