import {
  MDBCol,
  MDBRow
} from "mdb-react-ui-kit";
import "./CardHome.css";

const Card = (props) => {
  return (
    <div className="containerCard">
      <div className="infoCard">
        <MDBRow className='mt-3'>
          <MDBCol md="6" className='mb-2'>
            <h6>{props.title}</h6>
            <h5>{props.value}</h5>
          </MDBCol>
          <MDBCol md="6" className='mb-2'>
            <h6>{props.title_2}</h6>
            <h5>{props.value_2}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow className='mt-3'>
          <MDBCol md="6" className='mb-2'>
            <h6>{props.title_3}</h6>
            <h5>{props.value_3}</h5>
          </MDBCol>
          <MDBCol md="6" className='mb-2'>
            <h6>{props.title_4}</h6>
            <h5>{props.value_4}</h5>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
};

export default Card;
