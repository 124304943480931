import React, { useEffect, useState } from "react";
import './styles.css';

export default function Subheader({ title, icon }) {
  const [NomeEmp, setNomeEmp] = useState(null);
  const [CnpjEmpresa, setCnpjEmpresa] = useState(null);

  useEffect(() => {
    setCnpjEmpresa(sessionStorage.getItem("@CnpjEmpresa"));
    setNomeEmp(sessionStorage.getItem("@razao_social"));
  }, [CnpjEmpresa, NomeEmp]);

  return (
    <div className="subheader">
      <div className="alignment">
        <div className='eu'>
          <img src={require(`../../assets/icons/sidebar/${icon}.svg`)} alt="" />
          <h1>{" "+title}</h1>
        </div>

        <div>
          { CnpjEmpresa ? (
            <h1>{NomeEmp} / {cpf_Cnpj(CnpjEmpresa)}</h1>
          ) : (
            <h1>{NomeEmp}</h1>
          )}
        </div>
      </div>
    </div>
  )

  function cpf_Cnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }
}
