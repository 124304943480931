import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contratos from "../Pages/Contratos/Index";
import Login from "../Pages/TelaLogin/TelaLogin";

import PrivateRoute from "./privateRoutes";

const AppRouter = () => {  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="Home" element={<Contratos />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
