import SyncIcon from '@mui/icons-material/Sync';
import { Button, LinearProgress, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  MDBCol
} from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import TableFilter from 'react-table-filter';
import 'react-table-filter/lib/styles.css';
import ContratosContext from "../../context/ContratosContext";
import './Table.css';

const TableNota = (props) => {
  const [Notas, setNotas] = useState([]);
  const [Notas_o, setNotas_o] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const [Load, setLoad] = useState(false);

  const { Close } = useContext(ContratosContext);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Notas.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function _filterUpdated(newData, filterConfiguration){
    setNotas(newData);
  }

  useEffect(() => {
    if(props.Notas){
      Format(props.Notas)
    }
  }, [props.Notas]);

  function Format(notas){
    setLoad(true);
    //console.log(notas)
    for (let i = 0; i < notas.length; i++) {
      notas[i].emissao_f = new Date(new Date(notas[i].emissao).setHours(24)).toLocaleDateString('pt-BR');
      notas[i].quantidade_f = fDecimal(notas[i].quantidade) + " " + props.um;
      notas[i].vlr_total_f = props.moeda + "" + fDecimal(notas[i].vlr_total);
    }

    setNotas(notas)
    setNotas_o(notas)
    setLoad(false);
  }

  function sinc(){
    setNotas(Notas_o)
  }
  
  return (
    <>
      { Load ? (
        <MDBCol md="12" className='mb-2'><LinearProgress /></MDBCol>
      ) : null }

      <MDBCol md="12" className='mb-2' style={{ display: "flex", justifyContent: "flex-end"}}>
        <Button variant="contained" size="small" color="success" onClick={() => sinc()}>
          <SyncIcon /> Atualizar
        </Button>
      </MDBCol>

      { Notas.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table" style={{ fontSize: 10 }}>
              <TableFilter
                rows={Notas}
                onFilterUpdate={_filterUpdated}
              >
                <TableCell size={"small"} component={"th"} key={"n_nf"} filterkey="n_nf" showsearch={"true"}> Nº nota </TableCell>
                <TableCell size={"small"} component={"th"} key={"cfop"} filterkey="cfop" showsearch={"true"}> CFOP </TableCell>
                <TableCell size={"small"} component={"th"} key={"nat_op"} filterkey="nat_op" showsearch={"true"}> Operação </TableCell>
                <TableCell size={"small"} component={"th"} key={"quantidade_f"} filterkey="quantidade_f" showsearch={"true"}> QTD </TableCell>
                <TableCell size={"small"} component={"th"} key={"vlr_total_f"} filterkey="vlr_total_f" showsearch={"true"}> Vlr. Total </TableCell>
                <TableCell size={"small"} component={"th"} key={"emissao_f"} filterkey="emissao_f" showsearch={"true"} alignleft={"true"}> Emissão </TableCell>
              </TableFilter>
              
            
              <TableBody>
                {(rowsPerPage > 0 ? Notas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : Notas ).map((row) => (
                  <TableRow key={row.n_nf}>
                    <TableCell size={"small"}>{ lpad(row.n_nf) }</TableCell>
                    <TableCell size={"small"}>{ row.cfop }</TableCell>
                    <TableCell size={"small"}>{row.nat_op}</TableCell>
                    <TableCell size={"small"}>{ row.quantidade_f }</TableCell>
                    <TableCell size={"small"}>{ row.vlr_total_f }</TableCell>
                    <TableCell size={"small"}>{ row.emissao_f }</TableCell>
                  </TableRow>
                ))}
                { emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="TbContratos">
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  
                  count={Notas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={
                    ({ from, to, count }) => {
                      return 'Mostrando ' + from + '-' + to + ' de ' + count
                    }
                  }
                  labelRowsPerPage={ "Registros por página" }
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        </>
      ) : (
        <>
          <TableContainer component={Paper} className="TbContratos">
            <Table aria-label="custom pagination table" style={{ fontSize: 21 }}>
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell align="center" colSpan={12}>{"Sem registros."}</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
  
  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }
};

export default TableNota;