import React, { useEffect, useState } from "react";
import TelaContrato from "../../components/TelaContratos/Index.jsx";

import MultEmp from "../../components/MultEmp/Index.jsx";
import MultEmpContext from "../../context/MultEmpContext";

const ConfPedido = () => {
  const [IdEmp, setIdEmp] = useState(null);

  useEffect(() => {
    setIdEmp(sessionStorage.getItem("@IdEmpresa"));
  }, []);

  async function OK(){
    setIdEmp(sessionStorage.getItem("@IdEmpresa"));
  }

  return (
    <>
      { !IdEmp ?
        <>
          <MultEmpContext.Provider value={{ OK }}>
            <MultEmp open={sessionStorage.getItem("@IdEmpresa")} /> 
          </MultEmpContext.Provider>
        </>
      :
        <TelaContrato />
      }
    </>
  );
};

export default ConfPedido;
