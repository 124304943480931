import { createContext, useEffect, useState } from "react";
import auth from "../services/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [erro, setErro] = useState("");
  const [errorServer, seterrorServer] = useState("");
  const [loanding, setLoanding] = useState(false);

  const loadingStoreData = () => {
    const storageUser = sessionStorage.getItem("@Auth:nome");
    const storageToken = sessionStorage.getItem("@Auth:token");

    if (storageUser && storageToken) {
      setUser(storageUser);
    }
  };

     
  async function signIn(data){
    setLoanding(true);
    try {
      const response = await auth.post("usuarios/login", data);

      if (response.data.erro) {
        setErro("Usuário ou senha inválido");
        setLoanding(false);
      } else {
        //console.log(response.data)
        setUser(response.data);
        let Retorno = response.data;
        sessionStorage.setItem("@Auth:token", Retorno.token);
        sessionStorage.setItem("@Auth:user", data.email);
        if(Retorno.empresas.length === 1) {
          sessionStorage.setItem("@IdEmpresa", parseInt(Retorno.empresas[0].id));
          sessionStorage.setItem("@CnpjEmpresa", Retorno.empresas[0].cnpj);
          sessionStorage.setItem("@razao_social", Retorno.empresas[0].razao_social);
        }else{
          sessionStorage.setItem("@Empresas", JSON.stringify(Retorno.empresas));
        }

        setLoanding(false);
      }
    } catch (erro) {
      setErro("Ocorreu um erro. Tente novamente");
      setLoanding(false);
      seterrorServer(erro.message);
      console.log(erro);
    }
  };

  const limpaErro = () => {
    setErro("");
  };

  const singOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    setUser(null);
  };

  useEffect(() => {
    loadingStoreData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        singOut,
        signed: !!user,
        erro,
        limpaErro,
        loanding,
        errorServer
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
